.App {
	text-align: center;
	background-size: contain !important;
	min-height: 100vh;
}
.hr {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr.svg) 50% 0 no-repeat !important;
}
.hr2 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr2.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr2.svg) 50% 0 no-repeat !important;
}
.hr3 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr3.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr3.svg) 50% 0 no-repeat !important;
}
.hr4 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr4.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr4.svg) 50% 0 no-repeat !important;
}
.profilePhoto {
	box-shadow: 0px 2px 7px 0px #000000 !important;
	border: 1px solid var(--color1st) !important;
}
.bannerPhoto {
	clip-path: polygon(50% 100%, 100% 95%, 100% 0, 0 0, 0 95%) !important;
}

@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
.title {
	font-family: "Merienda", cursive !important;
}
#borderChoose {
	border: 1px solid white !important;
}
#borderChoose .hr,
#borderChoose .hr2,
#borderChoose .hr3,
#borderChoose .hr4 {
	background-color: white !important;
	width: 100% !important;
}
#borderChoose:hover .hr,
#borderChoose:hover .hr2,
#borderChoose:hover .hr3,
#borderChoose:hover .hr4 {
	background-color: var(--color1st) !important;
	width: 100% !important;
}

.MuiInputBase-formControl::before,
.MuiInputBase-formControl::after,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-color: var(--color1st) !important;
}

.MuiInputBase-input,
.MuiOutlinedInput-root,
.MuiInputLabel-root {
	background-color: transparent !important;
	color: var(--color1st) !important;
}
.icons .MuiButtonBase-root {
	background-color: transparent !important;
	border: 1px solid var(--color1st) !important;
	color: var(--color1st) !important;
}

.icons .MuiButtonBase-root:hover {
	background-color: var(--color1st) !important;
	color: white !important;
}
.icons .MuiButtonBase-root:hover .fi {
	background-color: var(--color1st) !important;
	color: white !important;
}
.contactList .MuiButtonBase-root,
.contactList .MuiButton-root {
	background-color: var(--color1st) !important;
	border: 1px solid var(--color1st) !important;
	color: white !important;
}
.contactList .MuiButtonBase-root:hover,
.contactList .MuiButton-root:hover {
	background-color: transparent !important;
	color: var(--color1st) !important;
}

.sendButton {
	background-color: var(--color1st) !important;
	color: white !important;
	border: 1px solid var(--color1st) !important;
}

.sendButton:hover {
	background-color: transparent !important;
	color: var(--color1st) !important;
}

.MuiButtonBase-root {
	background-color: var(--color1st) !important;
	color: transparent !important;
	color: white !important;
}
.MuiButtonBase-root:hover {
	background-color: white !important;
	color: var(--color1st) !important;
	border: 1px solid var(--color1st) !important;
}
.activeButton {
	background-color: white !important;
	color: var(--color1st) !important;
	border: 1px solid var(--color1st) !important;
}
.dashboard .MuiSvgIcon-root {
	width: 0.8em;
	height: 0.8em;
	min-width: 41px;
	color: white;
}
.dashboard .MuiListItemIcon-root {
	color: white;
	min-width: 22px !important;
}
.activeButton .MuiSvgIcon-root {
	color: var(--color1st) !important;
}
.MuiButtonBase-root:hover .MuiListItemIcon-root {
	color: var(--color1st) !important;
}
.MuiTableCell-head {
	color: var(--color1st) !important;
}
.MuiTableBody-root td {
	color: var(--color1st) !important;
	padding: 0 7px !important;
}
.activeBtnPayment {
	background-color: red !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--color1st) !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--color1st) !important;
}
.dashboard .MuiTypography-root {
	font-size: 0.8rem;
}
.dashboard .MuiListItem-root {
	color: white;
	padding: 4px 1px;
}
.MuiCheckbox-root {
	padding: 5px !important;
	border: 0px !important;
}
.MuiCheckbox-root:hover {
	border: 0px !important;
}
.dashboard .MuiListItem-root:hover .MuiSvgIcon-root,
.dashboard .MuiAccordionSummary-root:hover .MuiSvgIcon-root {
	color: var(--color1st);
}

.dashboard .link {
	text-decoration: none;
	font-weight: bold;
}

.dashboard .MuiAvatar-root {
	width: 65px;
	height: 65px;
}
.dashboard input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.dashboard .MuiAccordionSummary-content {
	margin: 0 !important;
}
.dashboard .MuiAccordionSummary-root {
	flex-direction: row !important;
	color: white;
	padding: 0;
	min-height: auto !important;
	padding: 7px 1px;
}
.dashboard .MuiAccordionSummary-root:hover {
	background-color: var(--color1st);
}
.dashboard .MuiAccordionDetails-root {
	padding: 0;
}
.dashboard .MuiList-root {
	padding: 0 !important;
	background-color: var(--color1st);
}
.dashboard .MuiAccordion-root {
	border: 1px solid white !important;
}
.swiper-pagination-bullet-active {
	background-color: var(--color1st) !important;
}
.logout {
	border: 2px solid white !important;
}
.textColor,
.MuiFormHelperText-root {
	color: var(--color1st) !important;
}
input.Mui-disabled,
.MuiOutlinedInput-root .Mui-disabled {
	-webkit-text-fill-color: var(--color1st) !important;
	color: var(--color1st) !important;
}
.borderColor {
	border-color: var(--color1st) !important;
}
.bgColor {
	background-color: var(--color1st) !important;
}

.loader span {
	background: var(--color1st) !important;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.MuiRating-root {
	color: var(--color1st) !important;
}

.share {
	opacity: 40% !important;
	cursor: pointer;
	z-index: 999;
}

.share:hover {
	opacity: 100% !important;
	background-color: transparent !important;
}

.share .icon {
	animation: zoom 2s linear infinite !important;
}

@keyframes zoom {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3);
	}

	100% {
		transform: scale(1);
	}
}
.swal2-container {
	z-index: 9999 !important;
}
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background: var(--color1st) !important;
}
.home {
	height: 100vh !important;
}
.marvel-device.note8 {
	margin: 0 auto !important;
	transform: scale(0.67) !important;
	-webkit-transform: scale(0.67) !important;
	transform-origin: 0 0 !important;
	-webkit-transform-origin: 0 0 !important;
	display: block !important;
	width: 415px !important;
	height: 830px !important;
}
.note8 .screen {
	background: white !important;
}
.marvel-device.note8 .shadow {
	box-shadow: inset 0 0 0 0 white, inset 0 0 30px 0 rgb(255 255 255 / 50%),
		0 0 0px 0 white, 0 0 0 0 rgb(255 255 255 / 50%) !important;
}

.schedule .MuiButtonBase-root {
	background-color: transparent !important;
	border: 1px solid var(--color1st) !important;
	color: var(--color1st) !important;
	margin: 3px;
}
.schedule .Mui-selected {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.schedule .MuiCalendarPicker-root {
	margin: 0 !important;
}

.schedule .selectedTime {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.schedule .confirm {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.MuiInputLabel-root {
	white-space: unset !important;
	font-size: small;
	font-weight: bold;
}
.invite .productCard {
	background-color: transparent !important;
	border: 1px solid var(--color1st) !important;
	color: var(--color1st) !important;
	border-radius: 11px;
}
.invite .button {
	background-color: var(--color1st) !important;
	color: white !important;
}
.invite .activeCard {
	background-color: transparent !important;
	border: 4px solid var(--color1st) !important;
	color: var(--color1st) !important;
	border-radius: 11px;
}
.MuiCalendarPicker {
	overflow: visible !important;
}
.wrapper {
	padding: 0.5rem;
	border: 1px solid var(--color1st) !important;
}
.editor {
	min-height: 15rem;
	border: 1px solid var(--color1st) !important;
	padding: 0 5px;
}
.toolbar {
	border: 1px solid var(--color1st) !important;
}
.username ::placeholder {
	color: var(--color1st) !important;
}

.username input[type="text"]:focus {
	border: 2px solid var(--color1st) !important;
	outline: none !important;
}
.pagination .MuiButtonBase-root {
	background-color: transparent !important;
	color: var(--color1st) !important;
	min-width: 30px !important;
	height: 30px !important;
}
.pagination .Mui-selected {
	background-color: var(--color1st) !important;
	color: white !important;
}
.textLeft {
	text-align: left !important;
}
.textRight {
	text-align: right !important;
}

.online .MuiBadge-badge {
	transform: scale(1) translate(0%, -0%) !important;
	border: 1px solid white;
}
.onlineProfile .MuiBadge-badge {
	transform: scale(1) translate(35%, -20%) !important;
	border: 1px solid white;
}

.react-player {
	height: auto !important;
	aspect-ratio: 16/9 !important;
	overflow: hidden !important;
}
.loading-logo {
	animation: rotation 8s infinite linear !important;
	border: 2px solid var(--color1st) !important;
	border-radius: 50% !important;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.dayCellEvent {
	height: 35px !important;
}
.eventTitle {
	font-size: 1.1rem !important;
	padding: 0 14px !important;
	line-height: normal !important;
}
.dailyEventTitle {
	font-size: 1rem !important;
	line-height: normal !important;
}
.manageShare .Mui-selected {
	background-color: white !important;
	color: var(--color1st) !important;
	border: 3px solid var(--color1st) !important;
}
.MuiPopover-paper {
	width: 300px !important;
}
.chat .MuiChip-label {
	white-space: break-spaces !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.calander .Mui-disabled {
	background-color: #80808061 !important;
	color: white !important;
}
.timeDate .Mui-selected {
	color: var(--color1st) !important;
	background-color: white !important;
	border: 3px solid var(--color1st) !important;
}
.dailyEventWrapper {
	padding: 0 !important;
}
/* ////////////////////////// */

.chat-body {
	height: 60vh;
	border: 1px solid var(--color1st) !important;
	border-radius: 9px;
	overflow: hidden;
}
.chat-window .chat-body .message-container {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
	display: none;
}
.chat-window .chat-body .message {
	height: auto;
	padding: 10px;
	display: flex;
}

.chat-window .chat-body .message .message-content {
	width: auto;
	height: auto;
	min-height: 40px;
	background-color: #e4e6eb;
	border-radius: 5px;
	color: black;
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-left: 5px;
	padding-right: 5px;
	padding-left: 5px;
	overflow-wrap: break-word;
	word-break: break-word;
	border-radius: 15px;
}

#you {
	justify-content: flex-start;
}

#you .message-content {
	justify-content: flex-start;
}

#you .message-meta {
	justify-content: flex-start;
	margin-left: 5px;
}

#other {
	justify-content: flex-end;
}

#other .message-content {
	justify-content: flex-end;
	background-color: #0084ff;
	color: white;
	border-radius: 15px;
}

#other .message-meta {
	justify-content: flex-end;
	margin-right: 5px;
}

.message-meta #author {
	margin-left: 10px;
	font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
	display: flex;
	font-size: 12px;
}
.boldVideoTitle {
	font-weight: bold !important;
}
.chat-bubble {
	background-color: #0084ff;
	padding: 11px 22px;
	-webkit-border-radius: 20px;
	-webkit-border-bottom-left-radius: 2px;
	-moz-border-radius: 20px;
	-moz-border-radius-bottomleft: 2px;
	border-radius: 20px;
	border-bottom-left-radius: 2px;
	display: inline-block;
}
.typing {
	align-items: center;
	display: flex;
	height: 5px;
}
.typing .dot {
	animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
	background-color: white;
	border-radius: 50%;
	height: 7px;
	margin-right: 4px;
	vertical-align: middle;
	width: 7px;
	display: inline-block;
}
.typing .dot:nth-child(1) {
	animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
	animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
	animation-delay: 400ms;
}
.typing .dot:last-child {
	margin-right: 0;
}

@keyframes mercuryTypingAnimation {
	0% {
		transform: translateY(0px);
		background-color: #6cad96;
	}
	28% {
		transform: translateY(-7px);
		background-color: #9ecab9;
	}
	44% {
		transform: translateY(0px);
		background-color: #b5d9cb;
	}
}

.billCheck input[type="checkbox"] {
	transform: scale(1.7) !important;
}
.react-quiz-container {
	width: 100% !important;
	margin: 0 auto !important;
}
.react-quiz-container h2 {
	font-size: 21px !important;
	font-weight: bold !important;
	color: var(--color1st) !important;
	padding: 7px 0 !important;
}
.react-quiz-container .startQuizBtn.btn {
	background-color: var(--color1st) !important;
	color: white !important;
	border-radius: 5px !important;
}
.react-quiz-container .questionWrapperBody h3 {
	margin: 5px 0;
	font-size: 17px !important;
	font-weight: bold !important;
	color: var(--color1st) !important;
}
.react-quiz-container .questionWrapperBody div {
	padding: 5px 0;
	font-size: 21px !important;
	font-weight: bold !important;
	color: var(--color1st) !important;
}
.react-quiz-container .questionWrapper .btn {
	color: var(--color1st) !important;
	background-color: white !important;
	border-radius: 5px !important;
	border: 1px solid var(--color1st) !important;
}
.react-quiz-container .questionModal .alert {
	color: white !important;
}
.react-quiz-container .questionWrapperBody .questionModal div {
	color: white !important;
}
.react-quiz-container .explaination,
.react-quiz-container .result-answer-wrapper h3 {
	color: var(--color1st) !important;
}
.react-quiz-container .quiz-synopsis {
	color: black !important;
}
.react-quiz-container .alert.correct div {
	font-size: medium !important;
}
.recharts-wrapper {
	width: 100% !important;
}
/* /////////// calender //////////// */
.react-calendar {
	width: 100% !important;
	height: 100% !important;
	padding: 0.5rem !important;
}

.react-calendar__tile {
	font-size: 1.3rem !important;
}

.highlighted {
	background-color: #00805b !important;
	color: white !important;
	border: 1px solid white !important;
}

.blocked {
	pointer-events: none !important;
}

.react-calendar__tile--now {
	background-color: var(--color1st) !important;
	color: white !important;
	border: 1.3px solid white !important;
}

.react-calendar__month-view__weekdays__weekday {
	font-weight: bold !important;
	font-size: 1rem !important;
	color: var(--color1st) !important;
	background-color: #dad9e5 !important;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
	text-decoration: none !important;
}

.react-calendar__navigation__label__labelText--from {
	font-size: 1.3rem !important;
	color: var(--color1st) !important;
}

.react-calendar__navigation button {
	font-size: 2rem !important;
	color: var(--color1st) !important;
}
